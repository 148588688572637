<template>
  <div class="eden-404">
    <h1 class="mb-2">404!</h1>
    <h3>Page does not exist!</h3>
    <div class="buttons">
      <div class="btn btn-primary">
        <a href="https://ouredenlife.com">
          Home
        </a>
      </div>
      <div class="btn btn-primary btn-outline">
        <a href="mailto:support@edenlife.ng">
          Support
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Error404'
}
</script>

<style scoped lang="scss">
.eden-404 {
  width: 100%;
  height: 90vh;
  background: #f8faf9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 12rem;
    color: #21312a;
    margin-bottom: 20px;
    line-height: 0.6;
  }

  h3 {
    text-transform: uppercase;
  }

  .buttons {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
}

@media (max-width: 600px) {
  h1 {
    font-size: 6rem !important;
  }
}
</style>
